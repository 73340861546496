import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { AppState, AppThunk } from "../main";
import axo from "src/utils/axo";
import { getCookie, hasCookie, setCookie } from "cookies-next";
import { increment } from "store/reducers/orderSummarySlice";
import { FormikHelpers } from "formik";
import router from "next/router";

export type CartState = {
  carts: CartItem[];
  cartSummary: CartSummary;
  loading: boolean;
  billingDetails: OrderUserRegistrationResp;
};

const initialState: CartState = {
  carts: [],
  cartSummary: {} as CartSummary,
  loading: false,
  billingDetails: {} as OrderUserRegistrationResp,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setItems: (state, action: PayloadAction<CartItem[]>) => {
      state.carts = action.payload;
    },
    setCartSummary: (state, action: PayloadAction<CartSummary>) => {
      state.cartSummary = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setBillingDetails: (
      state,
      action: PayloadAction<OrderUserRegistrationResp>
    ) => {
      state.billingDetails = action.payload;
    },
  },
});

export const { setItems, setCartSummary, setLoading, setBillingDetails } =
  cartSlice.actions;

// thunk action
export const addItem =
  (country: string | string[], detail: SummaryDetailProps): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    const { data, status } = await axo.post(`/add-item`, {
      country_code: country,
      guest_user_id: hasCookie("guestId") ? getCookie("guestId") : "",
      schedule_id: detail.scheduleId,
      schedule_detail_id: detail.scheduleDetailId,
      quantity: detail.numberOfParticipant,
    });
    if (data && status === 200) {
      dispatch(setLoading(false));
      if (!hasCookie("guestId")) {
        setCookie("guestId", data.guest_user_id);
      }
      router.push(`/${country as string}/order-summary`);
    }
  };
export const getItems =
  (coupon: string, country: string | string[], couponRemoved = ""): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    const { data, status } = await axo.post(`/get-cart-items`, {
      country_code: country,
      guest_user_id: hasCookie("guestId") ? getCookie("guestId") : "",
      coupon_code: coupon,
    });
    if (data && status === 200) {
      dispatch(setLoading(false));
      dispatch(setItems(data.carts.cart_item));
      dispatch(setCartSummary(data.carts.cart_summary));
      if (couponRemoved === "couponRemoved") {
        location?.reload();
      }
    }
  };

export const changeQuantity =
  (cartId: number, quantity: number, country: string | string[]): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    const { data, status } = await axo.post(`/change-quantity/${cartId}`, {
      country_code: country,
      quantity: quantity,
      guest_user_id: hasCookie("guestId") ? getCookie("guestId") : "",
      coupon_code: "",
    });
    if (data && status === 200) {
      dispatch(setLoading(false));
      dispatch(setItems(data.carts.cart_item));
      dispatch(setCartSummary(data.carts.cart_summary));
    }
  };

export const removeItems =
  (detail: CartBody, country: string | string[]): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    const { data, status } = await axo.delete(`/remove-items`, {
      data: {
        country_code: country,
        cart_id: detail.cart_id,
        guest_user_id: hasCookie("guestId") ? getCookie("guestId") : "",
        coupon_code: detail.coupon_code,
      },
    });
    if (data && status === 200) {
      dispatch(setLoading(false));
      dispatch(setItems(data.carts.cart_item));
      dispatch(setCartSummary(data.carts.cart_summary));
    }
  };
export const orderUserRegistration =
  (
    detail: OrderUserRegistrationDetail,
    formikActions: FormikHelpers<any>
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    const { data, status } = await axo.post(
      `/order-summary-user-registration`,
      detail
    );
    if (data && status === 200) {
      dispatch(setLoading(false));
      dispatch(increment());
      dispatch(setBillingDetails(data));
      formikActions.resetForm(); //reseting the form after success
    }
  };
export const checkOut =
  (detail: CheckOutBody): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    const { data, status } = await axo.post(`/checkout`, detail);
    if (data && status === 200) {
      dispatch(setLoading(false));
      router.push(`/${router.query.country as string}/payment-success`);
    }
  };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCarts = (state: AppState) => state.cart.carts;
export const selectCartSummary = (state: AppState) => state.cart.cartSummary;
export const selectLoading = (state: AppState) => state.cart.loading;

export default cartSlice.reducer;
