import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

import rootReducer from "./reducers";
import thunk from "redux-thunk";

// export function makeStore() {
//   return configureStore({
//     reducer: rootReducer,
//   });
// }
//Redux persist
const persistConfig = {
  key: "root",
  storage,
  blacklist: ["orderSummary", "carts"], //will not persist
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = makeStore();
export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export const persistor = persistStore(store);

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store;
