import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
// Create a theme instance.
let theme = createTheme({
  palette: {
    primary: {
      main: "#2FB1E6",
      // main: "#00a0d4",
    },
    secondary: {
      main: "#013277",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#F1F1F1",
    },
  },
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1170,
      xl: 1536,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 8,
      },
      sizeLarge: {
        padding: "12px 24px",
        fontSize: 16,
      },
      // containedPrimary: {
      //   color: "#ffffff",
      // },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 10,
      },
    },
    MuiAccordion: {
      rounded: {
        borderRadius: 10,
      },
      root: {
        borderRadius: 10,
      },
    },
    MuiTab: {
      root: {
        borderRadius: 10,
        transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "&$selected": {
          borderRadius: "10px 10px 0px 0px !important",
        },
      },
    },
    MuiStep: {
      root: {
        borderRadius: 10,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
    },
    MuiCssBaseline: {
      "@global": {
        html: {
          WebkitFontSmoothing: "auto",
        },
        body: {
          fontFamily: "Poppins",
          backgroundColor: "#ffffff",
        },
      },
    },
  },
  typography: {
    fontFamily: [
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    button: {
      textTransform: "capitalize",
    },
    h1: {
      fontSize: 36,
      fontWeight: 600,
    },
    h2: {
      fontSize: 30,
      fontWeight: 600,
    },
    h3: {
      fontSize: 24,
      fontWeight: 600,
    },
    h4: {
      fontSize: 20,
      fontWeight: 400,
    },
    h5: {
      fontSize: 18,
      fontWeight: 400,
    },
    h6: {
      fontSize: 16,
      fontWeight: 500,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
  },
  props: {
    MuiButton: {
      disableRipple: true,
      disableElevation: true,
    },
  },
});
theme = responsiveFontSizes(theme);
export default theme;
