import { combineReducers } from "@reduxjs/toolkit";
import orderSummaryReducer from "./orderSummarySlice";
import cartSlice from "./cartSlice";
import formReducer from "./formSlice";

const rootReducer = combineReducers({
  orderSummary: orderSummaryReducer,
  cart: cartSlice,
  form: formReducer,
});
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
